/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
import {Stack, Banner, FilePicker, Card} from '@servicetitan/design-system';
function _createMdxContent(props) {
  const _components = Object.assign({
    h1: "h1",
    a: "a",
    div: "div",
    p: "p",
    pre: "pre",
    code: "code",
    h2: "h2",
    hr: "hr"
  }, _provideComponents(), props.components);
  if (!Stack) _missingMdxReference("Stack", false);
  if (!Stack.Item) _missingMdxReference("Stack.Item", true);
  return React.createElement(React.Fragment, null, React.createElement(_components.h1, {
    id: "single-file-upload",
    style: {
      position: "relative"
    }
  }, "Single File Upload", React.createElement(_components.a, {
    href: "#single-file-upload",
    "aria-label": "single file upload permalink",
    className: "anchor after"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  }))), "\n", React.createElement(_components.p, null, "After uploading a single file, the button is replaced with a display of the file that was selected."), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-jsx"
  }, "<State initial={{}}>\n\t{([uploadFile, setUploadFile]) => (\n\t\t<FilePicker\n\t\t\tvalue={uploadFile.file}\n\t\t\taccept=\"image/* ,text/*, .csv, .xls, .xlsv\"\n\t\t\ttypesNote=\"Please provide file of type: any images, any text, .csv, .xls, .xlsv\"\n\t\t\tonSelected={(files) => {\n\t\t\t\tsetUploadFile({file: {file: files[0], downloadLink: '#'}});\n\t\t\t}}\n\t\t\tonDelete={(file) => {\n\t\t\t\tsetUploadFile({file: undefined});\n\t\t\t}}\n\t\t\tonReplace = {({file, newFile}) => {\n\t\t\t\tsetUploadFile({file: {file: newFile, downloadLink: '#'}});\n\t\t\t}}\n\t\t\tonDownload = {(file) => {\n\t\t\t\tconsole.log('download', file);\n\t\t\t}}\n\t\t/>\n\t)}\n</State>\n")), "\n", React.createElement(_components.h1, {
    id: "multiple-file-upload",
    style: {
      position: "relative"
    }
  }, "Multiple File Upload", React.createElement(_components.a, {
    href: "#multiple-file-upload",
    "aria-label": "multiple file upload permalink",
    className: "anchor after"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  }))), "\n", React.createElement(_components.p, null, "A user could also upload multiple files, with a configurable upper limit. Users can also bulk select their files during the upload process. The upload button is removed when the user hits the configurable limit."), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-jsx"
  }, "<State initial={{files: []}}>\n\t{([uploadFiles, setUploadFiles]) => (\n\t\t<FilePicker\n\t\t\tmultiple\n\t\t\tvalue={uploadFiles.files}\n\t\t\taccept=\"image/*, text/*, .csv, .xls, .xlsv\"\n\t\t\tonSelected={(files) => {\n\t\t\t\tconst existingFiles = uploadFiles.files\n\t\t\t\tObject.keys(files).map(\n\t\t\t\t\t(item) => {\n\t\t\t\t\t\t// add more checks here if you want more exact validation\n\t\t\t\t\t\texistingFiles.push({file: files[item]})\n\t\t\t\t\t}\n\t\t\t\t)\n\t\t\t\tsetUploadFiles({files: existingFiles});\n\t\t\t}}\n\t\t\tonDelete={(file) => {\n\t\t\t\tconst newFiles = uploadFiles.files.filter(value => value !== file);\n\t\t\t\tsetUploadFiles({ files: newFiles });\n\t\t\t}}\n\t\t\tonReplace = {({file, newFile}) => {\n\t\t\t\tconst files = uploadFiles.files;\n\t\t\t\tfor (let i = 0; i < files.length; i++) {\n\t\t\t\t\tif (files[i] === file) {\n\t\t\t\t\tfiles[i] = {file: newFile};\n\t\t\t\t\t}\n\t\t\t\t}\n\t\t\t\tsetUploadFiles({ files: files });\n\t\t\t}}\n\t\t\tonDownload = {(file) => {\n\t\t\t\tconsole.log('download',file);\n\t\t\t}}\n\t\t\tlimitReached={uploadFiles.files.length > 5}\n\t\t/>\n\t)}\n</State>\n")), "\n", React.createElement(_components.h1, {
    id: "allowed-file-types",
    style: {
      position: "relative"
    }
  }, "Allowed File Types", React.createElement(_components.a, {
    href: "#allowed-file-types",
    "aria-label": "allowed file types permalink",
    className: "anchor after"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  }))), "\n", React.createElement(_components.p, null, "The File Picker can be configured to only accept certain types of files. For example, if the configuration only accepted spreadsheet-type files, a user would be unable to select an image file. Accepted formats are automatically described below the 'Choose File(s)' button."), "\n", React.createElement(_components.h1, {
    id: "layouts",
    style: {
      position: "relative"
    }
  }, "Layouts", React.createElement(_components.a, {
    href: "#layouts",
    "aria-label": "layouts permalink",
    className: "anchor after"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  }))), "\n", React.createElement(_components.h2, {
    id: "list-view",
    style: {
      position: "relative"
    }
  }, "List View", React.createElement(_components.a, {
    href: "#list-view",
    "aria-label": "list view permalink",
    className: "anchor after"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  }))), "\n", React.createElement(_components.p, null, "Each file uploaded takes up the full width of the parent container."), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-jsx"
  }, "<State initial={{files: [{ file: 'sample_file.csv' }, { file: 'sample_file2.png' }]}}>\n\t{([uploadFiles, setUploadFiles]) => (\n\t\t<FilePicker\n\t\t\tmultiple\n\t\t\tvalue={uploadFiles.files}\n\t\t\taccept=\"image/*, text/*, .csv, .xls, .xlsv\"\n\t\t\tonSelected={(files) => {\n\t\t\t\tconst existingFiles = uploadFiles.files\n\t\t\t\tObject.keys(files).map(\n\t\t\t\t\t(item) => {\n\t\t\t\t\t\t// add more checks here if you want more exact validation\n\t\t\t\t\t\texistingFiles.push({file: files[item]})\n\t\t\t\t\t}\n\t\t\t\t)\n\t\t\t\tsetUploadFiles({files: existingFiles});\n\t\t\t}}\n\t\t\tonDelete={(file) => {\n\t\t\t\tconst newFiles = uploadFiles.files.filter(value => value !== file);\n\t\t\t\tsetUploadFiles({ files: newFiles });\n\t\t\t}}\n\t\t\tonReplace = {({file, newFile}) => {\n\t\t\t\tconst files = uploadFiles.files;\n\t\t\t\tfor (let i = 0; i < files.length; i++) {\n\t\t\t\t\tif (files[i] === file) {\n\t\t\t\t\tfiles[i] = {file: newFile};\n\t\t\t\t\t}\n\t\t\t\t}\n\t\t\t\tsetUploadFiles({ files: files });\n\t\t\t}}\n\t\t\tonDownload = {(file) => {\n\t\t\t\tconsole.log('download',file);\n\t\t\t}}\n\t\t\tlimitReached={uploadFiles.files.length > 5}\n\t\t/>\n\t)}\n</State>\n")), "\n", React.createElement(_components.h2, {
    id: "grid-view",
    style: {
      position: "relative"
    }
  }, "Grid View", React.createElement(_components.a, {
    href: "#grid-view",
    "aria-label": "grid view permalink",
    className: "anchor after"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  }))), "\n", React.createElement(_components.p, null, "The grid view is configured to three files per row, and is reduced to one on smaller displays."), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-jsx"
  }, "<State initial={{files: [{ file: 'sample_file.csv' }, { file: 'sample_file2.png' }, { file: 'text.txt' }, { file: 'larger_file_name_123456_final.xlsv' }, { file: 'file.gif' }]}}>\n\t{([uploadFiles, setUploadFiles]) => (\n\t\t<FilePicker\n\t\t\tvalue={uploadFiles.files}\n\t\t\tlayout='grid'\n\t\t\tmultiple\n\t\t\taccept=\"image/*, text/*, .csv, .xls, .xlsv\"\n\t\t\tonSelected={(files) => {\n\t\t\t\tconst existingFiles = uploadFiles.files\n\t\t\t\tObject.keys(files).map(\n\t\t\t\t\t(item) => {\n\t\t\t\t\t\t// add more checks here if you want more exact validation\n\t\t\t\t\t\texistingFiles.push({file: files[item]})\n\t\t\t\t\t}\n\t\t\t\t)\n\t\t\t\tsetUploadFiles({files: existingFiles});\n\t\t\t}}\n\t\t\tonDelete={(file) => {\n\t\t\t\tconst newFiles = uploadFiles.files.filter(value => value !== file);\n\t\t\t\tsetUploadFiles({ files: newFiles });\n\t\t\t}}\n\t\t\tonReplace = {({file, newFile}) => {\n\t\t\t\tconst files = uploadFiles.files;\n\t\t\t\tfor (let i = 0; i < files.length; i++) {\n\t\t\t\t\tif (files[i] === file) {\n\t\t\t\t\tfiles[i] = {file: newFile};\n\t\t\t\t\t}\n\t\t\t\t}\n\t\t\t\tsetUploadFiles({ files: files });\n\t\t\t}}\n\t\t\tonDownload = {(file) => {\n\t\t\t\tconsole.log('download',file);\n\t\t\t}}\n\t\t\tlimitReached={uploadFiles.files.length > 5}\n\t\t/>\n\t)}\n</State>\n")), "\n", React.createElement(_components.h1, {
    id: "alignment",
    style: {
      position: "relative"
    }
  }, "Alignment", React.createElement(_components.a, {
    href: "#alignment",
    "aria-label": "alignment permalink",
    className: "anchor after"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  }))), "\n", React.createElement(_components.p, null, "The arrangement of the upload button and any files can be left or centered aligned. By default, it is left aligned. Centering can be useful for layouts that are already center aligned."), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-jsx"
  }, "<State initial={{files: [{ file: 'sample_file.csv' }, { file: 'sample_file2.png' }, { file: 'text.txt' }, { file: 'larger_file_name_123456_final.xlsv' }, { file: 'file.gif' }]}}>\n\t{([uploadFiles, setUploadFiles]) => (\n\t\t<FilePicker\n\t\t\tvalue={uploadFiles.files}\n\t\t\talign='center'\n\t\t\tlayout='grid'\n\t\t\tmultiple\n\t\t\taccept=\"image/*, text/*, .csv, .xls, .xlsv\"\n\t\t\tonSelected={(files) => {\n\t\t\t\tconst existingFiles = uploadFiles.files\n\t\t\t\tObject.keys(files).map(\n\t\t\t\t\t(item) => {\n\t\t\t\t\t\t// add more checks here if you want more exact validation\n\t\t\t\t\t\texistingFiles.push({file: files[item]})\n\t\t\t\t\t}\n\t\t\t\t)\n\t\t\t\tsetUploadFiles({files: existingFiles});\n\t\t\t}}\n\t\t\tonDelete={(file) => {\n\t\t\t\tconst newFiles = uploadFiles.files.filter(value => value !== file);\n\t\t\t\tsetUploadFiles({ files: newFiles });\n\t\t\t}}\n\t\t\tonReplace = {({file, newFile}) => {\n\t\t\t\tconst files = uploadFiles.files;\n\t\t\t\tfor (let i = 0; i < files.length; i++) {\n\t\t\t\t\tif (files[i] === file) {\n\t\t\t\t\tfiles[i] = {file: newFile};\n\t\t\t\t\t}\n\t\t\t\t}\n\t\t\t\tsetUploadFiles({ files: files });\n\t\t\t}}\n\t\t\tonDownload = {(file) => {\n\t\t\t\tconsole.log('download',file);\n\t\t\t}}\n\t\t\tlimitReached={uploadFiles.files.length > 5}\n\t\t/>\n\t)}\n</State>\n")), "\n", React.createElement(_components.h1, {
    id: "content-guidelines",
    style: {
      position: "relative"
    }
  }, "Content Guidelines", React.createElement(_components.a, {
    href: "#content-guidelines",
    "aria-label": "content guidelines permalink",
    className: "anchor after"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  }))), "\n", React.createElement(_components.h2, {
    id: "the-button-name-should-start-with-the-verb-upload",
    style: {
      position: "relative"
    }
  }, "The button name should start with the verb “Upload”", React.createElement(_components.a, {
    href: "#the-button-name-should-start-with-the-verb-upload",
    "aria-label": "the button name should start with the verb upload permalink",
    className: "anchor after"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  }))), "\n", React.createElement(_components.p, null, "File picker buttons should begin with the verb “Upload” and follow with a noun that identifies the file type or purpose. Use “Upload Files” when multiple file types are allowed."), "\n", React.createElement(Stack, {
    spacing: 3
  }, React.createElement(Stack.Item, {
    grow: 1,
    basis: 0
  }, React.createElement(Card, null, React.createElement(Stack, {
    direction: "column",
    spacing: 2,
    alignItems: "flex-start"
  }, React.createElement(FilePicker, {
    buttonLabel: "Upload File"
  }), React.createElement(FilePicker, {
    buttonLabel: "Upload Images"
  }), React.createElement(FilePicker, {
    buttonLabel: "Upload Invoice"
  }))), React.createElement(Banner, {
    status: "success",
    title: "Do"
  })), React.createElement(Stack.Item, {
    grow: 1,
    basis: 0
  }, React.createElement(Card, null, React.createElement(Stack, {
    direction: "column",
    spacing: 2,
    alignItems: "flex-start"
  }, React.createElement(FilePicker, {
    buttonLabel: "Choose File"
  }), React.createElement(FilePicker, {
    buttonLabel: "Image upload"
  }), React.createElement(FilePicker, {
    buttonLabel: "Select Invoice"
  }))), React.createElement(Banner, {
    status: "critical",
    title: "Don't"
  }))), "\n", React.createElement(_components.h2, {
    id: "the-button-name-should-reflect-singular-or-plural",
    style: {
      position: "relative"
    }
  }, "The button name should reflect singular or plural", React.createElement(_components.a, {
    href: "#the-button-name-should-reflect-singular-or-plural",
    "aria-label": "the button name should reflect singular or plural permalink",
    className: "anchor after"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  }))), "\n", React.createElement(_components.p, null, "Avoid using “File(s)” to indicate a singular or plural. If there’s doubt about the number of files that will be uploaded, use the plural format."), "\n", React.createElement(Stack, {
    spacing: 3
  }, React.createElement(Stack.Item, {
    grow: 1,
    basis: 0
  }, React.createElement(Card, null, React.createElement(Stack, {
    direction: "column",
    spacing: 2,
    alignItems: "flex-start"
  }, React.createElement(FilePicker, {
    buttonLabel: "Upload Files"
  }))), React.createElement(Banner, {
    status: "success",
    title: "Do"
  })), React.createElement(Stack.Item, {
    grow: 1,
    basis: 0
  }, React.createElement(Card, null, React.createElement(Stack, {
    direction: "column",
    spacing: 2,
    alignItems: "flex-start"
  }, React.createElement(FilePicker, {
    buttonLabel: "Upload File(s)"
  }))), React.createElement(Banner, {
    status: "critical",
    title: "Don't"
  }))), "\n", React.createElement(_components.h2, {
    id: "use-inline-help-to-provide-file-requirements",
    style: {
      position: "relative"
    }
  }, "Use inline help to provide file requirements", React.createElement(_components.a, {
    href: "#use-inline-help-to-provide-file-requirements",
    "aria-label": "use inline help to provide file requirements permalink",
    className: "anchor after"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  }))), "\n", React.createElement(_components.p, null, "Use inline help inform the user of allowed file types, file size limit, and max number of uploads. Follow the content guidelines for helper text."), "\n", React.createElement(Card, null, React.createElement(Stack, {
    direction: "column",
    spacing: 3,
    alignItems: "flex-start"
  }, React.createElement(FilePicker, {
    buttonLabel: "Upload Image",
    typesNote: "Allowed file types: .jpg, .png, .pdf"
  }), React.createElement(FilePicker, {
    buttonLabel: "Upload PDF",
    typesNote: "Max file size 32 MB"
  }), React.createElement(FilePicker, {
    buttonLabel: "Upload Files",
    typesNote: "Up to 5 files"
  }))), "\n", React.createElement(Banner, {
    status: "success",
    title: "Do"
  }), "\n", React.createElement(_components.hr), "\n", React.createElement(_components.h1, {
    id: "importing",
    style: {
      position: "relative"
    }
  }, "Importing", React.createElement(_components.a, {
    href: "#importing",
    "aria-label": "importing permalink",
    className: "anchor after"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  }))), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-jsx"
  }, "code: true\n---\nimport { FilePicker } from '@servicetitan/design-system';\n")));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}
